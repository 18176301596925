import React from 'react'
import {
  Script,
  WrapPageElementNodeArgs,
  WrapPageElementBrowserArgs
} from 'gatsby'

type WrapPageElementArgs = WrapPageElementNodeArgs | WrapPageElementBrowserArgs

export const wrapPageElement = ({ element, props }: WrapPageElementArgs) => {
  return <>{element}</>
}
